import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import iconMetacognition from 'stile-shared/assets/icons/icon_metacognition.svg';
import iconParenting from 'stile-shared/assets/icons/icon_parenting.svg';
import iconPersonalGrowth from 'stile-shared/assets/icons/icon_personal_growth.svg';
import iconPrinting from 'stile-shared/assets/icons/icon_printing.svg';
import iconProfessionalDevelopment from 'stile-shared/assets/icons/icon_professional_development.svg';
import iconTimeOff from 'stile-shared/assets/icons/icon_time_off.svg';
import iconWellbeing from 'stile-shared/assets/icons/icon_wellbeing.svg';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { IconCardsBlock } from 'stile-shared/src/components/2020/blocks/IconCardsBlock';
import { MediaBlock } from 'stile-shared/src/components/2020/blocks/MediaBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { H2, P } from 'stile-shared/src/components/2020/common/Primitives';
import { Column } from 'stile-shared/src/components/2020/layout/Column';

import { getPageData } from 'utils/getPageData';

import { theme } from 'styles/theme';

import { PageLayout } from 'templates/2020/PageLayout';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';

export { Head } from 'templates/2020/Head';

function LifeAtStilePage(props: PageProps) {
  const { hero } = getPageData(props.location.pathname);

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        media={
          <MediaBlock as="div" layout="montage-five">
            <StaticImage
              src="../../../../stile-shared/assets/images/life-at-stile/life_at_stile_hero_1.png"
              alt="Several of the Stile team members at a picnic in a park"
            />
            <StaticImage
              src="../../../../stile-shared/assets/images/life-at-stile/life_at_stile_hero_2.png"
              alt="Four of Stile's staff smile at the camera in snow gear, with ski runs in the background"
            />
            <StaticImage
              src="../../../../stile-shared/assets/images/life-at-stile/life_at_stile_hero_3.png"
              alt="A Polaroid-style image of three staff members dressed up in yellow and laughing"
            />
            <StaticImage
              src="../../../../stile-shared/assets/images/life-at-stile/life_at_stile_hero_4.png"
              alt="Three team members in warm clothes, with a snowy background"
            />
            <StaticImage
              src="../../../../stile-shared/assets/images/life-at-stile/life_at_stile_hero_5.png"
              alt="One of Stile's staff smiling at the camera, with a black cap reading 'Head of Science' pulled over her eyes"
            />
          </MediaBlock>
        }
      />

      <TextBlock>
        <P>
          Stile is a place for people who want to have a bigger impact. For people who believe
          there’s room to improve our education system.
        </P>

        <P>
          It’s for people who deeply care about quality. It’s for people who prefer the outsized
          impact and quick decisions that can be made in a smaller company.
        </P>

        <P>
          It’s for people who value the team over the player. It’s a place for people who love
          working with great people and have fun doing it.
        </P>

        <P>
          We’re proud to work with a team of real, imperfect, amazing individuals who choose to
          spend time working towards Stile’s mission, and we’re determined to look after them.
        </P>
      </TextBlock>

      <IconCardsBlock
        max="1200px"
        cards={[
          {
            icon: { src: iconPrinting },
            title: 'Five weeks of annual leave',
            text: 'Take four weeks of leave in a year and get one more for free.',
          },
          {
            icon: { src: iconTimeOff },
            title: 'Remote road trips',
            text: 'Take off on a working holiday for a few weeks. Dial-in from the beach, the snow, whatever suits.',
          },
          {
            icon: { src: iconMetacognition },
            title: 'Sabbatical leave',
            text: 'After three years working at Stile, you can do a 3-month unpaid stint doing whatever you want!',
          },
          {
            icon: { src: iconWellbeing },
            title: 'Mental health support',
            text: 'Six free, confidential counselling sessions every year.',
          },
          {
            icon: { src: iconParenting },
            title: 'Family first',
            text: 'Birthing parents receive 12 weeks of paid leave and non-birthing parents receive 6 weeks of paid leave. Plus, we’ll continue to pay your 401k for the first 6 months of unpaid leave.',
          },
          {
            icon: { src: iconPersonalGrowth },
            title: 'Personal coaching',
            text: 'We’ll help you reach your goals through an annual budget of $2,000 for external professional development, plus internal professional development, career planning and productivity support.',
          },
          {
            icon: { src: iconProfessionalDevelopment },
            title: 'Sustainable living',
            text: 'We provide public transport co-contribution, free bike servicing, and end-of-trip facilities.',
          },
        ]}
      />

      <MediaBlock layout="center-align-three">
        <StaticImage
          src="../../../../stile-shared/assets/images/life-at-stile/life_at_stile_J_1.png"
          alt="A headshot of a woman smiling over at the camera"
        />
        <StaticImage
          src="../../../../stile-shared/assets/images/life-at-stile/life_at_stile_J_2.png"
          alt="A selfie including half the photographer's face. She is up close with a quokka, and another team member looks excited in the background"
        />
        <StaticImage
          src="../../../../stile-shared/assets/images/life-at-stile/life_at_stile_J_3.png"
          alt="Three team members in the office kitchen, cooking together. One is pulling a very long string of cheese out of a pan"
        />
      </MediaBlock>

      <TextBlock>
        <Column space={theme.space.l}>
          <Column>
            <Column space={0}>
              <H2>
                Collaborate with your team.
                <br />
                Jam out on your own.
              </H2>
            </Column>

            <P>
              We love working together and believe that in-person connection and collaboration is
              essential to create something truly special. That’s why all Stile teams spend time
              in-person together every week, be it in the office or a park.
            </P>

            <P>
              We’re about results, not time spent on seats. It matters to us what you do, not where
              you are or when you do it.
            </P>

            <P>
              We’re currently renovating Stile HQ, in Melbourne’s CBD, to be a purpose-built
              collaboration and meeting space for you and your team. For those who want to do solo
              work in the office, you’ll have your very own desk (not a hotdesk where someone else
              spilled coffee into the keyboard yesterday). Speaking of Stile HQ, the kitchen is
              stocked with specialty coffee, craft brew and local wines. It’s the home of team
              cook-ups and everything you need to make your favourite toastie.
            </P>
          </Column>

          <StaticImage
            src="../../../../stile-shared/assets/images/life-at-stile/spot_life_at_stile_collaboration.png"
            alt="Black and white illustration with splashes of green plants showing Stile employees collaborating at a whiteboard, playing with a cat, and working on a laptop."
          />
        </Column>
      </TextBlock>

      <TextBlock variant="dark" title="Leadership and management" space={theme.space.m}>
        <P>
          Stile is a team of humans, not robots. You can expect your manager to get to know you:
          your strengths, weaknesses, dreams and ambitions to help you succeed. You’ll meet with
          your manager at least once a week to check in.
        </P>

        <P>
          Stile’s leadership has an open-door policy. You’ll be invited to regular “ask me anything”
          events with the CEO, and can always book in dedicated weekly “office hours” with other
          senior members of the team. And, if you’re looking to eventually become a manager, we’ll
          give you opportunities and training to lead.
        </P>

        <P>
          We’re committed to a safe, inclusive and respectful workplace. We keep our finger on the
          pulse with how our team is feeling, and work with third-party consultants to review and
          iterate on what we’re doing.
        </P>
      </TextBlock>

      <MediaBlock layout="montage-two">
        <StaticImage
          src="../../../../stile-shared/assets/images/life-at-stile/life_at_stile_good_times_1.png"
          alt="A group shot of some of the Stile team. They are dressed up in silly outfits, or are in a goofy pose"
        />
        <StaticImage
          src="../../../../stile-shared/assets/images/life-at-stile/life_at_stile_good_times_2.png"
          alt="Several of the Stile team members are on the prow of a boat, in summer clothes or bathers"
        />
      </MediaBlock>

      <TextBlock title="Let the good times roll">
        <P>We really enjoy spending time together, in different groups, doing different things.</P>

        <P>
          We run a diverse range of company-wide social events throughout the year, from cooking
          classes, to bubble soccer in the park, and Lego building competitions.
        </P>
      </TextBlock>

      <NextPageBlock path="/who-we-are/our-team/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default LifeAtStilePage;
